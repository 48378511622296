@use "../variables";

@if variables.$site != variables.$nova {
    $form-max-width: variables.$mobile-break;
    $field-padding: 125px;
    $dynamic-padding: variables.$mobile-break - $field-padding;

    body {
        background: variables.$neutral-lighter-25;
    }

    .field-wrapper {
        border: 1px solid variables.$neutral-lighter-50;
    }

    .checkout__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        background: variables.$primary;
    }

    .currency {
        position: relative;
        right: 4px;
        top: -41px;
        text-align: right;
        margin-left: auto;
    }

    .readonly + .currency {
        top: -27px;
        color: variables.$neutral;
    }

    #lhv-application, #bigbank-form, #bigbank-flow-form, #checkout-payment {
        max-width: $form-max-width;
        margin: 16px auto 0 auto;

        .form-fields {
            display: flex;
            flex-direction: column;
            row-gap: 8px;
            padding: 16px clamp(16px, 100% - #{$dynamic-padding}, #{$field-padding});
            text-align: justify;

            &:nth-of-type(odd) {
                background: variables.$white;

                input:not(.readonly), [role=button] {
                    background: variables.$neutral-lighter-25;
                }
            }

            &:nth-of-type(even) {
                input, [role=button] {
                    background: variables.$white;
                }
            }

            &.disclaimer {
                color: variables.$neutral;
            }

            .residence-permit {
                display: none;

                &--active {
                    display: grid;
                }
            }
        }
    }

    .input-field__label {
        padding: 0;
    }

    #split-contract-fee-container .checkbox {
        margin-bottom: 16px;
    }

    .checkbox__label {
        line-height: initial;
        min-height: 24px; /* .checkbox__input::before {position: absolute} */
    }

    .readonly {
        border: none;
        padding: 0;
    }

    .iti {
        width: 100%;
    }

    .title {
        text-align: center;
        font-size: variables.$font-size-md;
        color: variables.$primary;
    }

    .label-tooltip {
        display: flex;
        justify-content: flex-start;
        text-align: left; // wrapping text left
        margin-top: 8px;
        width: 100%;
        color: variables.$neutral-light;
    }

    .limit-header {
        color: variables.$neutral;
        margin: 16px 0;
    }

    .checkbox_moreinfo {
        margin: 16px 0;
    }

    .icon[data-panel-target] {
        font-size: 16px;
    }

    // wide
    @media (min-width: variables.$responsive-break) {
        .checkout__logo {
            height: 72px;
        }
    }

    // narrow
    @media (max-width: variables.$responsive-break -1) {
        .checkout__logo {
            height: 56px;
        }
    }

    @media (max-width: variables.$mobile-break) {
        .limit-header {
            margin: 16px;
        }
    }

    #lhvInsuranceTermsBlock {
        display: flex;

        a {
            margin-top: 16px;
            line-height: 30px;
            margin-left: 10px;
        }
    }
}